import React, { useEffect } from 'react';
import styles from './ErrorBox.module.css';

import errorSoundSrc from 'assets/sounds/error.wav';
import error from 'assets/windowsIcons/897(32x32).png';

function lineBreak(str) {
  return str.split('\n').map((s, i) => (
    <p key={i} className={styles.errorMessage}>
      {s}
    </p>
  ));
}

function Error({ onClose, message = "Something's wrong!" }) {
  useEffect(() => {
    try {
      new Audio(errorSoundSrc).play();
    } catch (e) {
      console.log(e);
    }
  }, []);
  return (
    <div className={styles.error}>
      <div className={styles.errorTop}>
        <img src={error} alt="error" className={styles.errorImg} />
        <div className={styles.errorMessages}>{lineBreak(message)}</div>
      </div>
      <div className={styles.errorBottom}>
        <div onClick={onClose} className={styles.errorButton}>
          <span className={styles.errorConfirm}>OK</span>
        </div>
      </div>
    </div>
  );
}

export default Error;
