import React, { useState } from 'react';
import styles from './WindowDropDown.module.css';

import iePaper from 'assets/windowsIcons/ie-paper.png';
import ieBook from 'assets/windowsIcons/ie-book.png';
import check from 'assets/windowsIcons/checked.png';
import folder from 'assets/windowsIcons/folder.png';

export function WindowDropDown({ items, position = {}, onClick }) {
  const [option, setOption] = useState('');

  return (
    <div style={{ ...position }} className={styles.dropDownMenu}>
      {items.map((item, index) => {
        let rowClass = `${styles.dropDownRow} ${item.disable ? styles.dropDownRow__disable : ''
          } ${option === item.text ? styles.dropDownRow__active : ''}`;

        return (
          <div
            key={item.text}
            className={rowClass}
            onMouseEnter={() => setOption(item.text)}
            onClick={() => !item.disable && onClick(item.text)}
          >
            <div className={styles.dropDownCheck}>
              <RowSymbol type={item.symbol} />
            </div>
            <div className={styles.dropDownText}>{item.text}</div>
            <span className={styles.dropDownHotKey}>
              {item.hotkey || ''}
            </span>
            <div className={`${styles.dropDownArrow} ${item.type !== 'menu' ? styles.dropDownArrow__disable : ''}`} />
            {item.type === 'menu' && option === item.text && (
              <div style={{ position: 'relative' }}>
                <WindowDropDown
                  position={item.position}
                  items={item.items}
                  onClick={onClick}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

function RowSymbol({ type }) {
  const iconSrc = {
    'ie-paper': iePaper,
    'ie-book': ieBook,
    'folder': folder,
    'check': check,
  }[type];

  return type === 'circle' ? (
    <div
      className={styles.circle}
      style={{
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        backgroundColor: '#000',
      }}
    />
  ) : (
    <img className={styles.dropDownIcon} src={iconSrc} alt="" />
  );
}

export default WindowDropDown;
