import React, { useState } from 'react';
import styles from './Main.module.css';
import smile from '../smile.svg';

function Main({ onSearch }) {
  const [value, setValue] = useState('');

  function onChange(e) {
    setValue(e.target.value);
  }

  function onClick() {
    onSearch(value);
  }

  function onKeyDown(e) {
    if (e.key === 'Enter') {
      onSearch(value);
    }
  }

  return (
    <div className={styles.main}>
      <header className={styles.header}>
        <div className={styles.text}>Gmail</div>
        <div className={styles.text}>Images</div>
        <img className={styles.avatar} src={smile} alt="avatar" />
      </header>
      <section className={styles.content}>
        <img
          className={styles.logo}
          alt="Google"
          src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_272x92dp.png"
        />
        <div className={styles.searchBar}>
          <input
            id="search"
            type="text"
            name="search"
            onChange={onChange}
            value={value}
            onKeyDown={onKeyDown}
          />
          <div className={styles.icon}>
            <img
              src="https://www.gstatic.com/images/branding/googlemic/2x/googlemic_color_24dp.png"
              alt="microphone"
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <button className={styles.button} onClick={onClick} id="enter">Google Search</button>
          <button className={styles.button} >I'm Feeling Lucky</button>
        </div>
      </section>
      <footer className={styles.footer}>
        <section className={styles.upper}>
          <div className={`${styles.items} ${styles.left}`}>
            <div className={styles.item}>Taiwan</div>
          </div>
        </section>
        <section className={styles.lower}>
          <div className={`${styles.items} ${styles.left}`}>
            <div className={styles.item}>Advertising</div>
            <div className={styles.item}>Business</div>
            <div className={styles.item}>About</div>
          </div>
          <div className={`${styles.items} ${styles.right}`}>
            <div className={styles.item}>Privacy</div>
            <div className={styles.item}>Terms</div>
            <div className={styles.item}>Settings</div>
          </div>
        </section>
      </footer>
    </div>
  );
}

export default Main;
