import React, { useState, useEffect } from 'react';
import styles from './Balloon.module.css';

import risk from 'assets/windowsIcons/229(16x16).png';

function Balloon({ startAfter = 3000, duration = 15000 }) {
  const [show, setShow] = useState(true);
  const [start, setStart] = useState(false);
  useEffect(() => {
    const openTimer = setTimeout(() => setStart(true), startAfter);
    const fadeTimer = setTimeout(() => setShow(false), startAfter + duration);
    const closeTimer = setTimeout(
      () => setStart(false),
      startAfter + duration + 1000,
    );
    return () => {
      clearTimeout(openTimer);
      clearTimeout(fadeTimer);
      clearTimeout(closeTimer);
    };
  }, [startAfter, duration]);

  const animationClass = show ? styles.fadein : styles.fadeout;

  return (
    start && (
      <div className={`${styles.balloonContainer} ${animationClass}`}>
        <button onClick={() => setShow(false)} className={styles.balloonClose} />
        <div className={styles.balloonHeader}>
          <img className={styles.balloonHeaderImg} src={risk} alt="risk" />
          <span>Your computer might be at risk</span>
        </div>
        <p className={styles.balloonTextFirst}>
          Antivirus software might not be installed
        </p>
        <p className={styles.balloonTextSecond}>
          Click this balloon to fix this problem.
        </p>
      </div>
    )
  );
}

export default Balloon;
