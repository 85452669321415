import React, { useState, useRef, useEffect } from 'react';
import styles from './WindowDropDowns.module.css';
import WindowDropDown from '../WindowDropDown/WindowDropDown';

export function WindowDropDowns({ items, onClickItem, height = 20 }) {
  const dropDown = useRef(null);
  const [openOption, setOpenOption] = useState('');

  function hoverOption(option) {
    if (openOption) setOpenOption(option);
  }

  function _onClickItem(name) {
    setOpenOption('');
    onClickItem(name);
  }

  function onMouseUp(e) {
    if (!dropDown.current.contains(e.target)) setOpenOption('');
  }

  useEffect(() => {
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  return (
    <div className={styles.windowDropDowns} style={{ height: `${height}px`, lineHeight: `${height}px` }} ref={dropDown}>
      {Object.keys(items).map(name => (
        <div className={styles.dropDown} key={name}>
          <div
            onMouseDown={() => setOpenOption(name)}
            onMouseEnter={() => hoverOption(name)}
            className={`${styles.dropDownLabel} ${openOption === name ? styles.dropDownLabelActive : ''}`}
          >
            {name}
          </div>
          {openOption === name && (
            <WindowDropDown
              onClick={_onClickItem}
              items={items[name]}
              position={{ top: `${height}px`, left: '0' }}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default WindowDropDowns;