import React, { useState } from 'react';
import styles from './SubMenu.module.css';

function SubMenu({ data, style, onClick }) {
  const [hoverIndex, setHoverIndex] = useState(-1);
  return (
    <div style={{ ...style }} className={styles.subMenu}>
      {data.map((item, index) => (
        <SubMenuItem
          onClick={onClick}
          onHover={setHoverIndex}
          key={index}
          hover={hoverIndex === index}
          item={item}
          index={index}
        />
      ))}
    </div>
  );
}

const SubMenuItem = ({ index, item, hover, onHover, onClick }) => {
  function _onMouseOver() {
    onHover(index);
  }

  function _onClick() {
    onClick(item.text);
  }

  switch (item.type) {
    case 'item':
      return (
        <div
          onClick={_onClick}
          onMouseEnter={_onMouseOver}
          className={`${styles.item} ${hover ? styles.itemHover : ''}`}
        >
          <img className={styles.img} src={item.icon} alt="" />
          <div className={styles.text}>{item.text}</div>
        </div>
      );
    case 'separator':
      return <div className={styles.separator} />;
    case 'menu':
      return (
        <div
          onMouseEnter={_onMouseOver}
          className={`${styles.item} ${hover ? styles.itemHover : ''}`}
        >
          <img className={styles.img} src={item.icon} alt="" />
          <div className={styles.text}>{item.text}</div>
          {hover && (
            <div className={styles.arrow}>
              <SubMenu
                data={item.items}
                style={{ left: item.left, bottom: item.bottom }}
                onClick={onClick}
              />
            </div>
          )}
        </div>
      );
    default:
      return null;
  }
};

export default SubMenu;
